// Barlow Fonts

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Cfs1wH8RnA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Cfs7wH8.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPI42ogvTobdw.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPI42ouvTo.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPk5GogvTobdw.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPk5GouvTo.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3p-ks6VospT4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3p-ks51os.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7E_Ass52Hs.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7E_DMs5.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3_-gs6VospT4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3_-gs51os.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E30-8s6VospT4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E30-8s51os.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4s6VospT4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4s51os.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3q-0s6VospT4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3q-0s51os.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3j-ws6VospT4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3j-ws51os.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
//Barlow Fonts End

@font-face {
  font-family: "berlin_sans_fb_demibold";
  src:
    url("../fonts/berlin_sans_fb_demi_bold-webfont.woff2") format("woff2"),
    url("../fonts/berlin_sans_fb_demi_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  src:
    local("Pacifico Regular"),
    local("Pacifico-Regular"),
    url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
      format("woff2");
  font-display: swap;
}

@import "./tooltip.scss";

:root {
  --tg-body-font-family: "Poppins", sans-serif;
  --tg-heading-font-family: "Barlow", sans-serif;
  --tg-heading-font-family-old: "Poppins", sans-serif;
  --tg-berlin-font-family: "berlin_sans_fb_demibold";
  --tg-icon-font-family: "Font Awesome 5 Free";
  --tg-body-font-size: 16px;
  --tg-body-line-height: 1.75;
  --tg-heading-line-height: 1.2;
  --tg-common-color-blue: #0d6efd;
  --tg-common-color-indigo: #6610f2;
  --tg-common-color-purple: #6f42c1;
  --tg-common-color-pink: #d63384;
  --tg-common-color-red: #dc3545;
  --tg-common-color-orange: #fd7e14;
  --tg-common-color-yellow: #ffc107;
  --tg-common-color-green: #198754;
  --tg-common-color-teal: #68fb9a;
  --tg-common-color-cyan: #0dcaf0;
  --tg-common-color-rating: #faa706;
  --tg-common-color-white: #fff;
  --tg-common-color-gray: #191d26;
  --tg-common-color-black: #1b1f28;
  --tg-common-color-black-2: #201f2a;
  --tg-common-color-black-3: #0b0e13;
  --tg-common-color-black-4: #10181f;
  --tg-common-color-black-5: #090d10;
  --tg-common-color-black-6: #050506;
  --tg-common-color-black-7: #040608;
  --tg-common-color-black-8: #0d141b;
  --tg-common-color-black-9: #111922;
  --tg-body-color: #adb0bc;
  --tg-heading-color: #fff;
  --tg-theme-primary: #ed8f03;
  --tg-theme-primary-pallet: #ffb75e;
  --tg-theme-primary-darker: #51b18c;
  --tg-theme-secondary: #ffbe18;
  --tg-border-1: #19222b;
  --tg-border-2: #22292f;
  --tg-border-3: #2c3137;
  --tg-border-4: #333333;
  --tg-border-5: #1b242e;
  --tg-fw-extra-bold: 800;
  --tg-fw-bold: 700;
  --tg-fw-semi-bold: 600;
  --tg-fw-medium: 500;
  --tg-fw-regular: 400;
  --tg-fw-light: 300;
  --angle: 45deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: var(--tg-theme-primary);
  --c2: rgba(245, 161, 26, 0.38);
  --d: 3500ms;
}

html {
  background: #1b1f28 !important;
}

body {
  margin: 0;
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
  color: var(--tg-body-color);
  line-height: var(--tg-body-line-height);
  background: var(--tg-common-color-black);
  cursor: url(/assets/cursor.png), auto;
  position: relative;
}

#__next {
  height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
  margin-top: 0px;
  font-weight: var(--tg-fw-bold);
  line-height: var(--tg-heading-line-height);
  text-transform: uppercase;
}
.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem;
  }
}
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}

*,
::after,
::before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: var(--tg-common-color-gray);
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

span {
  color: var(--tg-heading-color);
  font: var(--tg-heading-font-family);
  font-weight: 500;
}

a,
button {
  color: var(--tg-theme-primary);
  outline: none;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:focus,
.btn:focus,
.button:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
button:hover {
  color: var(--tg-theme-secondary);
  text-decoration: none;
}
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

.list-wrap {
  margin: 0px;
  padding: 0px;
}
.list-wrap li {
  list-style: none;
}
p {
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  line-height: var(--tg-body-line-height);
  font-weight: var(--tg-fw-regular);
  color: var(--tg-body-color);
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid #b0b0b0;
  border-top: 0 none;
  margin: 8px 0;
  padding: 0;
}
label {
  color: var(--tg-heading-color);
  cursor: pointer;
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
}
input,
textarea {
  color: var(--tg-body-color);
}
input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--tg-common-color-black);
}
::-webkit-scrollbar {
  width: 8px;
  background-color: var(--tg-common-color-black);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--tg-theme-primary-pallet);
}
*::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  text-shadow: none;
}
::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  text-shadow: none;
}
::selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  text-shadow: none;
}
*::-moz-placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}
*::placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}

.tg-sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  height: auto;
  width: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7) !important;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;

  .navigation {
    li {
      a {
        padding: 10px 23px;
      }
    }
  }
}

.tgmobile__menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) e;
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: translateX(101%);
  -moz-transform: translateX(101%);
  -ms-transform: translateX(101%);
  -o-transform: translateX(101%);
  transform: translateX(101%);

  &.open {
    right: 300px;
  }
}
.tgmobile__menu .navbar-collapse {
  display: block !important;
}
.tgmobile__menu .nav-logo {
  position: relative;
  padding: 50px 25px;
  text-align: left;
}

.tgmobile__menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}
.tgmobile__menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid var(--tg-border-2);
}
.tgmobile__menu .navigation li.current > a::before {
  height: 100%;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 18px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--tg-common-color-white);
  background: var(--tg-common-color-gray);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn .plus-line {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 12px;
  height: 2px;
  background-color: var(--tg-common-color-white);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu
  .navigation
  li.menu-item-has-children
  .dropdown-btn
  .plus-line::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 2px;
  height: 12px;
  background-color: var(--tg-common-color-white);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open {
  background-color: var(--tg-theme-primary);
}
.tgmobile__menu
  .navigation
  li.menu-item-has-children
  .dropdown-btn.open
  .plus-line {
  background-color: var(--tg-common-color-black);
}
.tgmobile__menu
  .navigation
  li.menu-item-has-children
  .dropdown-btn.open
  .plus-line::after {
  display: none;
}
.tgmobile__menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 1.5;
  padding: 11px 60px 11px 25px;
  font-size: 15px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
}
.tgmobile__menu .navigation li > span {
  position: relative;
  display: block;
  line-height: 1.5;
  padding: 11px 60px 11px 25px;
  font-size: 15px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
}
.tgmobile__menu .navigation li > a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li ul li > a {
  margin-left: 20px;
}

.tgmobile__menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--tg-common-color-black);
  padding: 0px 0px;
  z-index: 5;
  box-shadow: -9px 0 14px 0px rgba(0, 0, 0, 0.06);
}
